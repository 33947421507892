
// load equal height js if there are any 'data-equalheight' found.
const eqh = document.querySelectorAll('[data-equalheight]');
if (eqh.length) {
    import(/* webpackChunkName: "equalHeight" */ './modules/equal-height').then(({ default: equalHeight }) => {
        equalHeight.init(eqh);
        // if the window resizes then redo the equal heights
        window.addEventListener('resize', function(event) {
            equalHeight.init(eqh);
        });
    }).catch(() => 'An error occurred while loading the component equalHeight');




}
